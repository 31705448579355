import communLangage from '@/commun/commun.langage.js';
export default {
     name: 'Propos',

     components:{

     },

     computed:{
          autreTexte() {
               return communLangage.autreTexte;
             },
     },

     data(){
          return{

          }
     },

     methods:{

     },

     created(){

     }
}